.plandetails {
  &-header {
    top: $navbarHeight * 1.33; // applies to position-sticky div
  }

  &-tabs {
    user-select: none;
    -webkit-user-drag: none;
    div {
      background-color: white;
      border: 1px solid $dark-grey;
      color: black;
      cursor: pointer;
      transition: all $easeInSpeed ease-in;

      overflow: hidden;
      text-overflow: ellipsis;

      height: 60px;
      padding-left: 20px;
      min-width: 164px;
      width: 164px;

      &:not(:first-child) {
        border-left: 0;
      }

      &:hover {
        background-color: $primaryFocusBackground;
        color: $primaryFocus;
        transition: all $easeOutSpeed ease-out; 
      }
    }

    .selected, {
      background-color: $primaryFocusBackground;
      border-bottom: 1px solid white;
      color: $primaryFocus;
      transition: all $easeOutSpeed ease-out;
    }

    .disabled {
      background-color: var(--bs-bdlight);
      color: $dark-grey;
      cursor: auto;

      &:hover {
        background-color: var(--bs-bdlight);
        color: $dark-grey;
      }
    }
  }

  &-tab {
    position: relative;
    width: 164px;
    min-width: 164px;
    height: 60px;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 75%;
    }
  }

  &-body {
    margin-top: -1px;
    border-top: 1px solid $dark-grey;

    padding-right: 50px;
    padding-left: 50px;
    padding-top: 30px;

    &-header {
      font-size: 18px;
      font-weight: 700;
      padding-bottom: 15px;
    }
  }

  &-star-rating {
    line-height: 30px;
  }

  // Unique table styling
  &-medication-cost-details {
    margin-left: 20px;
    &-drug-row {
      border: 1px solid $dark-grey;
      &-info {
        display: flex;
        flex-direction: column;
        line-height: 18px;

        text-overflow: clip;
        overflow: hidden;
        white-space: break-spaces !important;

        // Medication name
        :first-child {
          font-size: 15px;
          width: 180px;
          margin-top: 8px;
        }

        // Tier of medication
        :nth-child(2) {
          font-size: 12px;
          font-style: italic;
          width: 180px;
          margin-bottom: 8px;
        }
      }
    }

    &-drug-row:first-of-type {
      border-top: 0px solid transparent;
    }

    table {
      tr {
        height: 36px;
        line-height: 20px;
      }
    }
  }

  &-medication-cost-details, &-medication-cost {
    table {
      width: 100%;
      font-size: 15px;

      tr {
        overflow: hidden;
        white-space: nowrap;

        th {
          padding-left: 20px;
          max-width: 200px;
          white-space: break-spaces;
          line-height: 15px;
        }

        td {
          padding-left: 20px;
          max-width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: break-spaces;

          // Cost by pharmacy type has tds with internal padding
          &.plandetails-pharmacy-cell {
            padding-left: 0px;
          }
        }
      }
    }
  }

  // By month, by pharmacy are similar tables
  &-medication-cost {
    position: relative;

    &-table-header {
      padding-bottom: 8px;
      padding-top: 14px;
      padding-left: 14px;
    }

    &-table-body {
      border: 1px solid $dark-grey;

      // Border every tr element within the table
      > tr {
        border: 1px solid $dark-grey;
        background-color: #F8F9FA;

        &.disabled {
          background-color: var(--bs-bdlight);
          color: $dark-grey;
        }
      }
    }

    &-label {
      vertical-align: top;
      padding-top: 14px;
      padding-left: 14px;
    }

    &-list {
      padding-top: 14px;
      padding-bottom: 14px;

      background-color: inherit;

      display: flex;
      flex-direction: column;

      &.pharmacy {
        border-left: 1px solid $dark-grey;
        padding-left: 20px;

        // space between phase rows in table
        div {
          margin-bottom: 18px;
        }
        :last-child {
            margin-bottom: 0px;
        }
      }

      tr {
        background-color: inherit;
      }
    }
  }

  &-pharmacy-select {
    margin-top: 20px;
    display: inline-flex;
    line-height: 36px;
    padding-right: 20px;

    width: 100%;

    label {
      margin-right: 0px;
      margin-left: auto;
    }

    &-dropdown {
      // Remove default dropdown caret
      display: inline-flex;
      align-items: center;
      appearance: none;
      margin-left: 15px;
      padding-right: 24px;
      width: 180px;
      text-overflow: ellipsis;
      user-select: none;
    }
  }

  &-plan-documents {
    table {
      max-width: 100%;
      min-width: 200px;
    }

    td {
      padding-left: 32px;
      padding-right: 32px;
    }

    &-row {
      background-color: #F8F9FA;
      border: 1px solid $dark-grey;
      color: black !important;

      display: table;
      box-shadow: 0 1px 3px #CBCDCE;
      cursor: pointer;

      margin-bottom: 10px;

      font-size: 15px;
      width: 250px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      transition: all $easeOutSpeed ease-out; 

      &:hover {
        color: $primaryFocusBorder !important;
        background-color: $primaryFocusBackground;
        border: 1px solid $primaryFocusBorder;
        transition: all $easeInSpeed ease-in; 
      }

      a {
        color: black;
      }

      a:hover {
        color: black;
      }
    }

    &-icon {
      font-size: 18px;
      color: var(--bs-grey);
      margin-right: 10px;
    }

    &-name {
      color: black;
    }
  }
}

.plandetails-empty {
  color: $dark-grey;
  user-select: none;
}

// Charts, override all of the computed styles
.VictoryContainer {
  svg {
    overflow: visible;
  }
}

.effective-date-warning-div {
  background-color: var(--bs-light-red);
  margin-top: 24px;
  width: 50%;
  padding-left: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
}

@media (max-width: 800px) {
  .plandetails-header-plan-id {
    flex-direction: column;
  }
}

// Element (type) | (carrier) | (name)
// e.g.    MAPD | Somewhere | Somewhere Premier Plan (HOOT)
.plan-tri-header {
  font-size: 18px;
  font-weight: 500;
  max-height: 20px;

  display: flex;
  flex-direction: row;

  & #type {
    font-weight: 700;
    color: $primaryFocus;
    margin-right: 3px;

    margin-right: 8px;
    border-right: 1px solid var(--bs-grey);
    padding-right: 4px;

    // Necessary when SOABadge is shown
    display: flex;
    align-items: center;
  }

 & #carrier {
    display: inline-flex; 
    font-weight: 700;

    margin-right: 8px;
    border-right: 1px solid var(--bs-grey);
    padding-right: 8px;
  }

 & #name {
    font-style: italic;
    text-decoration: underline;
    font-weight: 500;

    margin-right: 12px;
  }
}

// Blue clickable headers
.plandetails-table-header {
  position: relative;
  height: 50px;
  width: 100%;

  background-color: $primaryFocusBackground;
  border: 1px solid $primaryFocusBorder;
  border-radius: 2px;
  box-shadow: 0 -1px 6px $primaryFocusShadow;

  font-size: 18px;
  line-height: 50px;
  color: black;
  font-weight: 700;
  user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;

  padding-left: 20px;

  // Left-aligned up/down caret
  &-toggle {
    position: absolute;
    right: 16px;
    top: 12px;
    height: 26px;
    width: 26px;

    color: $primaryFocusBorder;
    background-color: $primaryFocusBackground;

    text-align: center;
    font-size: 22px;
    line-height: 32px;

    cursor: pointer;
  }
}

// Content below plandetails-headers - e.g. lists, charts
.plandetails-table {
  width: 100%;
  transition: all 1s ease;
  padding-left: 20px;
  font-size: 15px;
  line-height: 25px;

  &-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
  }

  &-row-sm {
    height: 38px;
    background-color: #F8F9FA;
    border: 1px solid $dark-grey;
    border-top: 0px solid transparent;
    line-height: 38px;
    font-size: 15px;
  }

  // Left column of table
  &-detail-name {
    position: absolute;
    left: 20px;
    width: 27.5%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  // Right column
  &-detail-value {
    position: inherit;
    left: 30%;
    width: 70%;
  }

  .coverage-phase { background-color: var(--bs-light-green); }
  .restrictions { background-color: var(--bs-light-red); }
}


.plandetails-ci {
  padding-left: 20px;
  font-size: 15px;
  transition: all 1s ease;

  table { margin-bottom: 40px; }

  thead td {
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    font-weight: 700;
  }

  tbody tr {
    background-color: #F8F9FA;
    border: 1px solid $dark-grey;

    height: 36px;
    line-height: 36px;

    td {
      padding-left: 20px;
    }
  }
}
