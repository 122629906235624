$width_right_bar: 360px;
$width_toggler: 40px;

.mmb-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .mmb-tab-item {
    height: 100%;
    padding: 4px;
    border-right: 1px solid #ccc;
    text-align: center;
    cursor: pointer;

    &:last-child {
      border-right: unset;
    }

    &.active {
      border-bottom: 4px solid $success;
    }
  }
}

.flex-1 {
  flex: 1;
}