.three-way thead {
  background-color: #d9e2f3;
}

.three-way th {
  padding: 1rem;
}

.details th {
  text-align: end;
}

.call-tools:disabled {
  border: 0px solid  #ffffff;
}