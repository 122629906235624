.ellipses-loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsisloading steps(4,end) 900ms infinite;
  animation: ellipsisloading steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsisloading {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsisloading {
  to {
    width: 1.25em;
  }
}

// Spinners
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.mmb-loading-spinner {
  display: inline-block;
  vertical-align: text-bottom;
  // border: 0.3em solid $primaryFocusShadow;
  // border-right-color: $primaryFocus;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

