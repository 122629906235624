input, select, .form-control, .form-select, {
  border-radius: 4px;
}
input:focus, .form-control:focus{
  box-shadow: 0 0 0 4px $primaryFocusShadow;
}
.form-control.shadow-fix:focus{
  box-shadow: 0 0 0 4px $primaryFocusShadow;
  margin-right: -2.1rem;
  padding-right: 2rem;
  z-index: 0;
}
.cursor-pointer { cursor: pointer; }
.r-0 { border-radius: 0px; }
.r-1 { border-radius: 2px; }
.r-2 { border-radius: 4px; }
.r-3 { border-radius: 6px; }

// Text
.fw-bold, b {
  font-weight: 700 !important;
}
.fw-bolder {
  font-weight: 900 !important;
}

// Opacity
.o-0 { opacity: 0; }
.o-1 { opacity: 0.3; };
.o-2 { opacity: 0.6; };
.o-4 { opacity: 1 !important; };
.overflow-fade {
    -webkit-mask-image: -webkit-linear-gradient(black, 60%, black, rgba(0, 0, 0, 0))
}

// Animations
.animation-open {
  transition: all $easeInSpeed ease-in;
  overflow: hidden;
}
.fade {
  transition: opacity $easeInSpeed ease-in;
  overflow: hidden,
}

// Animated ellipsis line
.text-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Toast overrides
.Toastify__toast-container {
  top: 100px;
  z-index: 4100; // navbar zindex is 4200, it now jumps out from under it
  width: 400px;
}

