.agent-sort-icon:hover {
  cursor: pointer;
}

.border-4 {
  border-width: 4px;
}

#user-sidebar {
  $row-height: 4rem;
  $user-sidebar-close: 3.1rem;
  $user-sidebar-open: 23rem;

  z-index: 1000;
  top: 0;
  right: 0;
  padding-top: 4rem !important;

  .selected-users-table {
    display: none;
  }

  .icon-group {
    height: $row-height;

    &:hover {
      .highlight:not(.active) {
        background-color: $secondary;
      }
    }
  }

  width: $user-sidebar-close;

  ~ .agent-list-container {
    margin-right: $user-sidebar-close !important;
  }

  &.open {
    width: $user-sidebar-open;

    ~ .agent-list-container {
      margin-right: $user-sidebar-open !important;
    }

    > .icon-group-container .side-bar-text {
      display: none !important;
    }

    > .icon-group-container .selected-users-table {
      display: block !important;
    }
  }
}

.vertical-text {
  transform: rotate(90deg);
	transform-origin: center;
  white-space: nowrap;
}
