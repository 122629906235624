.planlist {
  &-recommended {
    background-color: var(--bs-bdlight);
    border: 1px solid $dark-grey;
    border-radius: 2px;

    font-size: 15px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;

    padding-left: 60px;
    padding-right: 60px;
    padding-top: 30px;
    padding-bottom: 30px;

    &-row {
      display: flex;
      width: 100%;
    }

    &-col {
      flex-basis: 0;
      flex-grow: 1;

      // Force table cell borders to connect
      margin-left: -1px;
      height: auto;

      &-header {
        padding-left: 20px;

        &-carrier {
          font-size: 18px;
          font-weight: 700;
        }

        &-plan-type {
          color: $primaryFocus;
          font-weight: 700;
          user-select: none;
          display: flex;
          align-items: center;
          position: relative;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
        }

        &-name {
          font-style: italic;
          text-decoration: underline;
        }
      }

      // Force each columns' data to expand to full width
      table {
        width: 100%;

        td {
          line-height: 36px;
        }
      }

      &:not(:first-child) {
        border-left: 8px solid $dark-grey;

        td {
          padding-left: 20px;      
        }
      }
    }

    tr {
      height: 36px;
      border-top: 1px solid $dark-grey;

      &:first-child {
        border-top: 0;
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;

      margin-left: 5px;
      margin-top: 30px;

      button {
        margin-left: 15px;
        margin-bottom: 0;
      }

      &-buttons {
        display: flex;
        flex-direction: row;
      }
    }
  }

  &-compare-footer {
    height: 70px;

    left: 0;
    bottom: 0;

    padding-left: 120px;
    padding-right: 120px;

    box-shadow: 0 -1px 6px #00000040;

    z-index: 4200;

    user-select: none;
    -webkit-user-drag: none;
    white-space: nowrap;

    transition: all $easeOutSpeed ease-in-out;

    &-toggle {
      position: absolute;
      width: 60px;
      height: 18px;
      line-height: 18px;

      top: -18px;
      right: 15px;
      font-size: 24px;
      text-align: center;

      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      background-color: $primary;
      cursor: pointer;

      svg {
        margin-top: -2px;
      }
    }

    &-container {
      display: flex;
      flex-grow: 1;
      flex-basis: 0;
      width: 100%;
      margin-top: 4px;
    }

    &-plancard {
      position: relative;
      align-items: center;

      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-basis: 0;
      height: 62px;

      padding-right: 15px;
      padding-left: 15px;

      background-color: white;
      border: 1px solid $secondary;
      border-left: 2px solid $primary;
      border-right: 2px solid $primary;
      color: black;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      button {
        line-height: 15px;
      }

      &-remove {
        color: grey;
        font-size: 18px;
        font-weight: 700;
        position: absolute;
        top: 3px;
        right: 8px;
        cursor: pointer;
      }

      .plan {
        margin-right: 0px;
        overflow: hidden;
      }

      .plan-type {
        font-weight: 700;
        color: $primaryFocus;
        height: 12px;
        line-height: 14px; 
      }

      .plan-carrier {
        border-left: 1px solid var(--bs-grey);
        margin-left: 4px;
        padding-left: 4px;
        font-weight: 700;
        line-height: 14px; 
      }

      .plan-name {
        font-size: 13px;
        font-style: italic;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

// Filter/sort dropdowns
.planlist-tooltip-panel {
  position: absolute;
  top: 36px;
  left: 0px;

  min-width: 100px;
  padding: 0;
  line-height: 36px;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;

  font-size: 15px;
  white-space: nowrap;
  border: 1px solid #ced4da;
  box-shadow: 0px 3px 8px #00000040;
  background: white;
  display: none;
  z-index: 5;

  &.open {
    display: flex;
    max-height: 40vh;
    overflow-y: scroll;
    /* Place above RTS status on plancards */
    z-index: 6;
  }

  label, input {
    cursor: pointer;
  }

  input {
    transform: scale(1.2);
  }

  > div {
    align-items: center;
    user-select: none;
    color: black;
    font-weight: 500;
  }

  > span {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    font-weight: 500;
    color: black;
    align-items: center;
    cursor: pointer;
  }

  &-choice {
    transition: all 50ms ease;
    &:hover, &.selected  {
      background-color: $primaryFocusBackground;
      color: $primaryFocus;
    }
  }
}

#planlist-recommended-empty {
  user-select: none;
  -webkit-user-drag: none;
}

.doctors-in-network {
  cursor: pointer;
  text-decoration: underline;
  text-weight: 700;
  user-select: none;
  border: 0;

  &.recommended-doctors {
    color: $blue;
    background-color: var(--bs-bdlight);
    border: 0;
  }

  &.plancard-doctors {
    color: $blue;
    background-color: transparent;
  }
}

// Recommended Enroll/details buttons
@media (max-width: 1300px) {
  .planlist-recommended-footer {
    flex-direction: column;

    button {
      margin-bottom: 8px;
    }
  }
}

// todo(joeysapp): Add in stuff for mobile view of rec/planlist
@media (max-width: 1425px) {

  // Quote/details on top of each other to fit
  .plancard-col-overview-buttons {
    flex-direction: column;

    :first-child {
      margin-right: 0px;
      margin-bottom: 8px;
    }
  }

  // Force all info to be column structured to fit
  .plancard-col-features:not(.pdp), .plancard-col-medical {
    div {
      line-height: 18px;
      flex-direction: column;
    }
  }

  .planlist-recommended-footer-buttons {
    flex-direction: column;
  }
}



