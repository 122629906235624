.input-group {
    .btn {
        border-left-width: 0;
    }

    .form-control {
        border-right-width: 0;

        &+* {
            border: 1px solid #505050;
            border-left-width: 0;
            padding-left: 6px;
        }

        &.is-invalid,
        &.is-invalid+* {
            border-color: #D65656 !important;

        }
    }
}

.hide-icon {
    .form-select {
        background-image: unset !important;
        padding-right: $input-padding-x  !important;
    }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: $danger  !important;
}

.form-select.disabled {
    background-color: #e9ecef;
}

.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 20px;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 20px;
    }
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #02020261;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown {

    min-width: 160px;

    .dropdown-toggle {
        width: 0;
        text-align: left;
        min-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &::after {
            display: none;
        }

        .arrow-carret {
            position: absolute;
            right: 10px;
            bottom: 7px;
        }
    }

    &.show {
        .arrow-carret {
            transform: scaleY(-1);
        }
    }

    &.dropdown-2 {
        .dropdown-toggle {
            padding-left: 30px;
            border: unset;
            border-radius: 4px;
            box-shadow: none;

            .arrow-carret {
                left: 10px;
                right: unset;
            }
        }

        &.show {
            .dropdown-toggle {
                background-color: $nbgrey;
            }
        }
    }

    .dropdown-menu {
        max-height: 400px;
        overflow-y: auto !important;
        position: absolute;
    }

    .dropdown-item {
        position: relative;
        padding-left: 1.25rem;
        line-height: 20px;

        &::before {
            display: none;
            content: "";
            position: absolute;
            width: 5px;
            height: 100%;
            background-color: $primaryFocusBorder;
            left: 0;
            top: 0;
        }

        &.active,
        &:active {
            color: #212529;
            font-weight: bold;
            background-color: unset !important;

            &:before {
                display: block;
            }
        }
    }

    &.dropdown-2 .dropdown-item {
        padding-left: 1.8rem;

        &::before {
            display: none;
        }

        .arrow-checked {
            position: absolute;
            top: 8px;
            left: 10px;
        }
    }
}

.large {
    input[type='checkbox'] {
        width: 20px;
        height: 20px;
    }

    label {
        font-size: 18px;
    }
}

.check-yes-no-blank {
    display: flex;
    gap: 2px;
    text-decoration: none !important;
    padding: 0 !important;

    .check-icon {
        width: 20px;
        height: 20px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        // yes
        &.status-1 {
            border-color: #002E47;
            background-color: #002E47;
        }

        &.status-2 {
            border-color: rgba(0, 0, 0, 0.4);
            background-color: white;
        }

        &.status-blank {
            border-color: rgba(0, 0, 0, 0.25);
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}
