.printable-form {
  margin-left: 2.5vw;
  margin-top: 2.5vh;
}

.page {
  font-family: serif;

  min-width: 210mm;  
  max-width: 210mm;
  min-height: 297mm;
  max-height: 297mm;
  margin-bottom: 5vh;

  border: 1px solid black;
  box-decoration-break: clone;
}

.sans-serif {
  font-family: sans-serif;
}

/* Hide the margins/headers on print dialogs (@page !== .page) */
@page {
  size: A4;
  margin: 0;
}

@media print {
  // note(joeysapp): This will apply to all printed pages from the agent-frontend
  html {
    width: 210mm;
    height: 297mm;
    margin: 0;
    padding: 0;
  }

  .printable-form {
    margin: 0;
  }

  .page {
    border: none;
    margin: 0;
  }
}
