.soa {
  padding-top: 22px;
  padding-bottom: 100px;
}

$nav-border-color: solid 1px #CBCDCE;
// $nav-border-color: solid 1px black;

.greeting-color {
  background-color: #FFFCD0;
  &:focus { background-color: #FFFCD0; }
}

.closing-color {
  background-color: #D4F7E3;
  &:focus { background-color: #D4F7E3; }
}

.signature-color {
  background-color: #DAE5F5;
  &:focus { background-color: #DAE5F5; }
}

.soa-body {
  border-top: $nav-border-color;

  margin-top: -1px;
  padding-top: 1.5rem;
  padding-left: 40px;
  padding-right: 40px;
}

.soa-approval-span {
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 3px;

  position: relative;
  border: 1px solid transparent;
  
  &:hover, &.open {
    background-color: #dfe4e6;
    border: 1px solid #ced4da;
  }
}

.soa-approval-popover {
  display: none;
  position: absolute;
  background-color: white;
  border-radius: 3px;
  padding: 12px;

  flex-direction: column;
  z-index: 3;
  cursor: initial;
  top: 125%;

  &.open {
    display: flex;
    box-shadow: 0 -1px 6px #CBCDCE;
    border: 1px solid #ced4da;
  }

}
