// Base Sass variables
$primary: #002E47;
$secondary: #707070;
$warning: #F4E7A5; //#ffc107;
$warning-bg: #fff9d2;
$warning-border: rgba(120, 100, 15, 0.5);
$danger: #D65656;
$info: #0D96CC;
$success: #198754;
$success-bg: #DFFBEE;
$green: #3D8E65;
$light-green: #F3FFE1;
$red: #E50218;
$light-red: #FFE3D9;
$blue: #0058FF;
$white: #FFF;
$light: #F5F5F5;
$grey: #848484;
$dark: #505050;
$black: #000;
$highlight: #FFD1BA;
$highlight-bg: #FFF2EB;
$highlight-em: #FFFF90;
$nbgrey: #BABABA; // Navbar
$bgcolor: #F1F1F1;
$bddark: #E8E8E8;
$bdlight: #F8F9FA; 

$faStarGold: #FFBA00;

// MMB-unique values
$border-color: #151D5A;
$link-color: $secondary;
$highlightFocusBorder: #1947F3;
$primaryFocus: #0D96CC;
$primaryFocusShadow: rgb(13 150 204 / 25%);
$primaryFocusBackground: #E5F8FF;
$primaryFocusBorder: #008AB5;
$primaryHighlight: #E6F9FF; // Used for Inactive Leads dropdown on Dashboard
$primaryHighlightBackground: #CBF1FD;

$input-border-color: $dark;
$enable-validation-icons:     false !default;
$form-label-margin-bottom:    .35rem !default;
$input-padding-x: .75rem !default;

// button
$btn-focus-box-shadow: unset !default;
$btn-focus-width: 0 !default;


// Various shades of grey used for borders/bgs
$bright-grey: #FAFAFA;
$light-grey: #F5F5F5;
$medium-grey: #E5E5E5;
$dark-grey: #E2E2E2;
$darker-grey: #D1D3D4;
$darkest-grey: #3B4043;
/* no, use these now */
$white:       #ffffff;  // https://getbootstrap.com/docs/5.0/utilities/background/
$grey-100:    #f8f9fa;  // Greyscale colors are also available,
$grey-200:    #e9ecef;  // but only a subset are used to generate any utilities.
$grey-300:    #dee2e6;  // note(joeysapp): Include them so we can use them, e.g. bg-grey-200
$grey-400:    #ced4da;  //                 instead of "lightest-light-lighter-grey"
$grey-500:    #adb5bd;
$grey-600:    #6c757d;
$grey-700:    #495057;
$grey-800:    #343a40;
$grey-900:    #212529;
$black:       #000000;


// Sizing
$border-radius: .1rem;
$border-radius-lg: .15rem;
$border-radius-sm: .05rem;

$container-max-widths: (
  xl: 960px,
);

$spacers: (
  0: 0rem,
  1: .25rem,
  2: .50rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem,
  6: 6rem,
  7: 12rem,
);

$font-sizes: (
  1: 42px,
  2: 24px,
  3: 21px,
  4: 18px,
  5: 15px,
  6: 13px,
);

// Animation variables
$easeInSpeed: 0.10s;
$easeOutSpeed: 0.25s;

// Size for @media queries
$phoneMaxWidth: 775px;

// -- Accessing above items
//
// Sass map overriding base Bootstrap theme. All items in this map have '--bs-' prepended.
// This is used for className specifications, e.g. btn-primary.
// https://getbootstrap.com/docs/5.0/customize/sass/#maps-and-loops
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "warning": $warning,
  "warning-bg": $warning-bg,
  "warning-border": $warning-border,
  "info": $info,
  "danger": $danger,
  "success": $success,
  "success-bg": $success-bg,
  "light": $light,
  "dark": $dark,

  "black": $black,
  "blue": $blue,
  "green": $green,
  "light-green": $light-green,
  "grey": $grey,
  "darker-grey": $darker-grey,
  "light-grey": $light-grey,
  "medium-grey": $medium-grey,
  "red": $red,
  "light-red": $light-red,
  "highlight": $highlight,
  "highlight-bg": $highlight-bg,
  "highlight-em": $highlight-em,
  "nbgrey": $nbgrey,
  "bgcolor": $bgcolor,
  "bddark": $bddark,
  "bdlight": $bdlight,
  "white": $white,
  "grey-100":            $grey-100,
  "grey-200":            $grey-200,
  "grey-300":            $grey-300,
  "grey-400":            $grey-400,
  "grey-500":            $grey-500,
  "grey-600":            $grey-600,
  "grey-700":            $grey-700,
  "grey-800":            $grey-800,
  "grey-900":            $grey-900,

  "primaryFocus": $primaryFocus,
  "primaryFocusBackground": $primaryFocusBackground,
  "primaryFocusBorder": $primaryFocusBorder,

  "faStarGold": $faStarGold,
);

$dropdown-link-hover-bg: $primaryHighlightBackground !default;

// This method can used to access our Sass variables in CSS via var(--name), if we know
// we won't need the color for any bootstrap helper classes. (SassScript interpolation)
$somevar: 42px;
:root {
  --somevar: #{$somevar};
}
