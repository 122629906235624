.rx,
.doctors {
  padding-top: 22px;
  overflow: hidden;
  background-color: white;
  font-size: 15px;
}

// Force entire page below header to be grey
.rx-add:after,
.doctor-add:after {
  background-color: red;
  content: '';
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #F8F9FA;
  z-index: -1;
}

.rx-list {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
}

.drug-search-results {
  position: absolute;
  z-index: 4100;
  width: 360px;
  max-height: 360px;
  overflow-y: scroll;
}

.text-input-spinner {
  position: absolute;
  margin-left: 90%;
  margin-top: 5px;
  z-index: 4100;
}

.btn-brand-v-generic {
  border: solid 1px $dark-grey;
  background-color: white;
}

.btn-brand-v-generic-active {
  background-color: $primaryFocusBackground;
  border: solid 1px $primaryFocusBorder;
}

.drug-list-table th,
.pharmacy-list-table th {
  border: none !important;
}

.drug-list-table td,
.pharmacy-list-table td {
  border: none !important;
}

.drug-list-table tbody tr:nth-child(odd) {
  background-color: var(--bs-bdlight);
}

.drug-list-table tbody tr,
.pharmacy-list-table th tbody tr {
  border: solid 1px $dark-grey;
}

.pharmacy-box {
  background-color: $primaryFocusBackground;
  border: solid 1px $primaryFocusBorder;

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  margin-right: 0;
  margin-left: auto;

  font-size: 15px;
  font-weight: 500;
}

.pharmacy-add-button {
  width: 125px;
  display: block;
  font-weight: 700;
}

.pharmacy-list-table {
  font-size: 15px;

  tbody tr {
    border: 1px solid $medium-grey;
  }

  tbody tr td {
    height: 46px;
    background-color: #F8F9FA;
    vertical-align: middle;
  }

  > :not(:first-child) {
    border-top: 0px;
  }

  th {
    font-weight: 600;
    font-size: 18px;
  }
}

.pharmacy-list-table-row.selected tr {
  border: 1px solid $primaryFocus;
}

.pharmacy-list-table-row.selected td {
  background-color: $primaryFocusBackground;
}

.selected-pharmacy-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  >span {
    margin-left: 0;
    margin-right: auto;
  }

  :first-child {
    font-weight: 700;
    user-select: none;
  }

  &:first-of-type {
    margin-right: 20px;
  }
}

.delete-drug-modal-container {
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
}

.rx-trash-icon {
  color: lightgray;
  padding: 0;
}

.table-divider {
  border: none !important;

  td {
    height: 15px !important;
    background-color: white !important;
  }
}