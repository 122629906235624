// Global unified design components, explicitly named
// for searching/understandability.
// note(@joeysapp): @joeysapp DO NOT NEST FURTHER THAN 3 DEEP

// Page Elements
.mmb-container {
  padding-top: 22px;
  overflow: hidden;
  background-color: white;
  font-size: 15px;
}

.mmb-header {
  font-size: 21px;
  font-weight: 700;
  margin-left: 30px;
  margin-right: 30px;

  display: flex;
}

.mmb-content {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
}

// Basic elements
.mmb-link {
  cursor: pointer;
  color: $blue;
  text-decoration: underline;
  user-select: none;
  width: 100%;
}

.mmb-select {
  // Inline-flex div to wrap <select> elements for custom carets
  display: inline-flex;
  align-items: center;
  width: 100%;

  // Hide default caret, only immediate <select> descendant
  >select {
    appearance: none;

    user-select: none;
    text-overflow: ellipsis;
    padding-right: 32px;
  }

  // Taken from form-control
  color: #495057;
}

// Tabs
.mmb-tab-container {
  display: flex;
  margin-left: 30px;
  margin-top: 14px;

  font-size: 15px;
  font-weight: 500;

  user-select: none;
  -webkit-user-drag: none;
}

.mmb-tab {
  align-items: center;
  display: flex;
  background-color: white;
  border: 1px solid $dark-grey;
  color: black;
  cursor: pointer;
  transition: all $easeInSpeed ease-in;
  font-weight: 700;

  overflow: hidden;
  text-overflow: ellipsis;

  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 164px;
  width: 164px;

  position: relative;

  // Span used for display count of items within a tab
  .mmb-tab-count {
    font-style: italic;
    font-weight: 500;
    right: 20px;
    position: absolute;

    align-items: center;
    margin-right: auto;
    user-select: none;
    -webkit-user-drag: none;
  }

  &:not(:first-child) {
    border-left: 0;
  }

  &:not([disabled]):hover {
    background-color: $primaryFocusBackground;
    color: $primaryFocus;
    transition: all $easeOutSpeed ease-out;
  }

  &[disabled] {
    color: $grey;
    cursor: not-allowed;
  }
}

.mmb-tab-selected {
  background-color: $primaryFocusBackground;
  border-bottom: 1px solid white;
  color: $primaryFocus;
  transition: all $easeOutSpeed ease-out;
}

.mmb-tab-disabled {
  background-color: var(--bs-bdlight);
  color: $dark-grey;
  cursor: auto;

  &:hover {
    background-color: var(--bs-bdlight);
    color: $dark-grey;
  }
}

.mmb-search {
  border-top: solid 1px #E8E8E8;

  margin-top: 20px;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.mmb-modal {
  line-height: 25px;
  padding: 30px;
  padding-left: 40px;
  padding-right: 40px;

  white-space: nowrap;
  overflow: hidden;

  font-size: 15px;

  // Immediate child div selector (the header)
  >div:first-of-type {
    font-size: 18px;
    font-weight: 700;
  }
}
