.shopper-page {
  padding-left: 30px;

  &-header {
    font-weight: 700;
    font-size: 21px;

    span {
      font-weight: 500;
      font-style: italic;
      color: $primaryFocus;
    }
  }

  &-body {
    padding-right: 80px;
    padding-top: 20px;
  }
}

.shopper-page-row, .shopper-page-dynamic {
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  width: 100%;

  .client-info-input {
    width: 100%;

    padding-right: 15px;
    padding-left: 15px;
  }

  .client-info-input-label {
    white-space: nowrap;
  }

  #subsidy-input {
    max-width: 75%;
  }

  #update-disposition {
    max-width: 50%;
  }
}

.shopper-page-tab:hover {
  opacity: 0.7;
  cursor: pointer;
}

/* Prevent placeholder text from being capitalized */
::-webkit-input-placeholder {
  text-transform: none;
}

#shopper-id {
  display: flex;
  height: 100%;
  position: relative;

  & span {
    margin: auto;
  }

  & .copy {
    color: white;
    padding: 0.3rem;
    font-size: 1.5rem;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}

#shopper-id:hover {
  text-decoration: underline;

  & .copy {
    background-color: $secondary;
  }

  & .copy:hover {
    background-color: $primary;
  }

  & .copy:active {
    background-color: #dd520f;
  }
}

#shopper-id .copyText {
  background-color: $secondary;
  border-radius: 0.1rem;
  bottom: -25%;
  left: 15%;
  color: white;
  line-height: 2rem;
  opacity: 0;
  padding: 0.25rem;
  position: absolute;
  transition-delay: 0.25s;
  transition: opacity 1s;
  text-align: center;
  width: 120px;
  z-index: 5;
}

#shopper-id .copyTextClicked {
  opacity: 1 !important;
  transition-delay: 2s;
  transition: opacity 0.5s;
}

#shopper-id .copyText::after {
  content: ' ';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $secondary transparent;
}

#shopper-id-button:focus {
  text-decoration: none;
}

@media (max-width: 1000px) {
  .shopper-page-body {
    padding-right: 180px;
  }

  .shopper-page-dynamic {
    flex-direction: column;

    #subsidy-input {
      max-width: 100%;
    }
  }
}
