// MMB Custom Pagination Styling
$border-radius: 2px;
$pagingation-bg: white;
$pagination-border-color: var(--bs-light-grey);

// Selected Page
$pagination-active-color: white;
$pagination-active-bg: var(--bs-light-grey);
$pagination-active-border-color: var(--bs-light-grey);

$pagination-hover-color: white;
$pagination-hover-bg: var(--bs-light-grey);
$pagination-hover-border-color: var(--bs-light-grey);

// Disabled pagination
$pagination-disabled-color: var(--bs-nbgrey);
$pagination-disabled-bg: white;
$pagination-disabled-border-color: var(--bs-light-grey);

$pagination-disabled-hover-color: var(--bs-nbgrey);
$pagination-disabled-hover-bg: white;
$pagination-disabled-hover-border-color: var(--bs-light-grey);



.pagination {
  display: flex;
  margin-top: 20px;
  @include list-unstyled();
  @include border-radius();
}

.page-link {
  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -$pagination-border-width;
  color: $pagination-color;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  &:hover {
    z-index: 2;
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 2;
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }
}

.page-item {
  font-size: 15px;
  font-weight: 700;

  &:first-child {
    .page-link {
      margin-left: 0;
    }
  }
  &:last-child {
    .page-link { }
  }

  &.active .page-link {
    z-index: 1;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;

    &:hover {
      color: $pagination-disabled-hover-color;
      background-color: $pagination-disabled-hover-bg;
      border-color: $pagination-disabled-hover-border-color;      
    }
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm);
}
