$guide-alpha: 0.8;
$guide-bg-color: $black;

@keyframes guideColors {
  0% { color: $white }
  50% { color: $primary }
  100% { color: $white }
}

.guide-icon {
  animation: guideColors 3s infinite;
}

.guide-wrap {
  &:not([data-open=true]) {
    display: none;
  }
  &[data-hovering=true] {
    .guide-icon:not(:hover) {
      opacity: 0;
    }
    .guide-close {
      opacity: 0;
    }
  }

  z-index: 999999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: none;
}

.guide-bg {
  z-index: 200000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($guide-bg-color, $guide-alpha);
}

.guide-close {
  transition: opacity .3s;
  position: absolute;
  z-index: 290000;
  margin: auto;

  &[data-side^=t] {
    top: 0;
  }
  &[data-side$=r] {
    right: 0;
  }
  &[data-side^=b] {
    bottom: 0;
  }
  &[data-side$=l] {
    left: 0;
  }
}

.guide-text {
  $spacer: 0.25rem;

  pointer-events: none;
  display: none;
  position: absolute;
  width: 25rem;
  text-align: center;

  &[data-side^=t] {
    bottom: 100%;
    margin-bottom: $spacer;
  }
  &[data-side^=b] {
    top: 100%;
    margin-top: $spacer;
  }
  &[data-side$=r] {
    left: 100%;
    margin-left: $spacer;
    text-align: left;
  }
  &[data-side$=l] {
    right: 100%;
    margin-right: $spacer;
    text-align: right;
  }
}

.guide-marker {
  transition: width .8s, height .8s, opacity .8s;
  z-index: 290000;
  box-shadow: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($light, 0.1);

  .guide-icon {
    transition: width .8s, height .8s, opacity .5s;
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    z-index: 280000;
    box-shadow: 0 0 0 9999px rgba($guide-bg-color, $guide-alpha);
    background-color: transparent;

    ~ .guide-bg {
      opacity: 0;
    }

    .guide-icon {
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: rgba($guide-bg-color, $guide-alpha);

      & * {
        opacity: 0;
      }
    }
    .guide-text {
      display: block;
    }
  }
}
