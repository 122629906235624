.mpa-highlight-header {
  color: var(--primary);
}

.mpa-row {
  margin-left: 0rem;
  margin-right: 0rem;
}

.mpa-col {
  border: solid 1px lightgrey;
  border-right: solid 1px lightgrey;
  border-bottom: none;
}

.mpa-col-highlight {
  border: solid 1px var(--bs-primary);
  border-bottom: none;
  background-color: #fff2eb;
  font-weight: bold;
}

.mpa-col-summary {
  font-weight: 500;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mpa-col-summary-highlight {
  border-left: 1px solid var(--bs-primary);
  border-right: 1px solid var(--bs-primary);
  background-color: #fff2eb;
  font-weight: bold;
}

.mpa-table {
  width: 100%;
}

.mpa-table tr td {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  padding: .5rem;
  padding-left: 1rem;
  font-size: 90%;
}

.mpa-recommendation {
  border: 2px solid red;
  padding: .8rem;
  font-weight: bold;
  color: red;
}
.mpa-col-total {
  border: 1px solid $primaryFocus;
  background-color: $primaryHighlightBackground;
  padding: 1rem;
  padding-right: 3rem;
  text-align: right;
  font-size: 1.5rem;
}
.mpa-col-total-stay {
  margin-right: 33.3%;
}

.mpa-letter-link {
  color: var(--bs-primary);
}

.custom-table {
  td {
    border: 1px solid lightgrey;
  }

  th {
    vertical-align: bottom;
  }

  th,
  td {
    text-align: center;
    padding: 0.5rem;
    &.active {
      border-right-color: $primaryFocus;
      background-color: $primaryHighlightBackground;
      font-weight: bold !important;
    }
  }
  .border-right-primaryFocus {
    border-right-color: $primaryFocus;
  }
  tbody td.active.plan-name {
    border-top-color: $primaryFocus;
  }
  .mpa-col-total {
    border: 1px solid $primaryFocus;
    background-color: $primaryHighlightBackground;
    padding: 1rem;
    padding-right: 3rem;
    text-align: right;
    font-size: 1.5rem;
  }
  tr:nth-last-child(2) td {
    border-bottom-color: $primaryFocus;
  }
}

