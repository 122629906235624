.enroll-quote-card {
  box-shadow: 0px 3px 8px #00000040;
  max-height: 0px;
  opacity: 0.5;
  margin-bottom: 0px;
  transition: all 0.75s ease;
  overflow: hidden;
  background-color: $bdlight;
  border: 1px solid $darker-grey;
  &.open { max-height: 1000px; opacity: 1; margin-bottom: 25px; }
  &.new { border: 1px solid $primaryFocusBorder; background-color: $primaryFocusBackground }
}
