.custom-popover {
  max-width: none;
  padding: 1rem;
  box-shadow: 3px 3px 3px 3px grey;
}



//
// Provider Coverage Popover (n of n doctors covered)
// Used in:
// * /common/ProviderCoveragePopover
// 

// Unique positioning
.planlist-provider-coverage-popover {
  // Have it go to the left of the component a %, so page scaling works
  left: 132.5% !important;
  bottom: -70px !important;
}

.recommended-provider-coverage-popover {
}

.plandetails-provider-coverage-popover {
}


// Generalities that apply to all provider coverage popovers
.provider-coverage-popover-container {
  background: white;
  cursor: auto;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
  float: left;
  opacity: 1;
  position: absolute !important;
  z-index: 100;

  min-height: 317px;
  max-height: 317px;

  min-width: 317px;
  max-width: 317px;

  border: 1px solid $blue !important;

  // Generally, our `n of n` text works with these values
  left: 115px;
  bottom: -85px;
}

// Internal to container body 
.provider-coverage-popover {
  margin: 20px;
  margin-top: 30px;
  white-space: break-spaces;
  line-height: normal;

  &-exit {
    color: grey;
    cursor: pointer;
    position: absolute;
    right: 14px;
    top: 12px;
    font-size: 18px;
    font-weight: 700;
  }

  &-list {
    max-height: 195px;
    min-height: 195px;
    overflow-y: scroll;
    line-height: normal;

    display: flex;
    flex-direction: column;
    margin-top: 12px;
    border: 1px solid lightgrey;

    position: relative;

    &-item {
      display: flex;
      flex-direction: column;
      line-height: normal;

      padding-left: 10px;
      padding-right: 21px;
      padding-top: 5px;
      padding-bottom: 5px;

      position: relative;
      border-bottom: 1px solid lightgrey;
    }

    // https://stackoverflow.com/a/24227736
    // Indicate more items in a scrollable list
    &-shade {
      position: absolute;
      bottom: 19px;
      width: 275px;
      height: 30px;
      z-index: 10;
      opacity: 1;
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(152,152,152,.4) 40%,rgba(23,23,23,0.45) 90%,rgba(0,0,0,0.5) 99%); /* W3C */
      pointer-events: none;

      &.hidden {
        opacity: 0;
      }
    }
  }

  &-in-network {
    font-size: 24px;
    position: absolute;
    right: 16px;
    top: 0px;
  }
}

.provider-coverage-popover:before {
  border-color: transparent #ffffff transparent transparent;
  border-style: solid;
  border-width: 12px 50px 12px 12px;
  content: "";
  left: -50px;
  position: absolute !important;
  top: 195px;
  z-index: -1;
}
