#doctor-zip-input {
  width: 70px;
}

#doctor-select-specialty-input {
  width: 150px;
}

.doctor-card {
  background-color: white;
  margin-bottom: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-right: .6rem;
  border: solid 1.5px $dark-grey;
  width: 900px;
  box-shadow: 0 2px 3px $dark-grey;
}

.doctor-important-star {
  color: white;
  stroke: black;
  stroke-width: 32px;

  font-size: 20px;
  width: 20px !important;
  height: 20px !important;

  &.selected {
    color: $faStarGold;
  }

  &.hover {
    color: rgba($faStarGold, 25%); 
  }
}

#doctor-search-loader {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
}

#doctor-search-disclaimer {
  height: 20rem;
  overflow-y: scroll;
  border: solid 1.5px $dark-grey;
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 0;
  background-color: white;
}

#doctor-search-disclaimer::-webkit-scrollbar {
  width: 25px;
  background-color: white;
}

#doctor-search-disclaimer::-webkit-scrollbar-track {
  background-color: $light-grey;
  width: 40px;
}

#doctor-search-disclaimer::-webkit-scrollbar-thumb {
  border: solid 2px lightgray;
  background-color: $dark-grey;
}
