html {
  overflow-y: scroll;
  /* Prevent bouncing/rubberbanding on overscrolls */
  overscroll-behavior-y: none;
}

body {
  font-family: 'Source Sans Pro', Helvetica;
}

input:-webkit-autofill {
  font-family: 'Source Sans Pro', Helvetica;

  // Force autofill background to be white
  -webkit-box-shadow: 0 0 0 100px #fff inset;
  -moz-box-shadow: 0 0 0 100px #fff inset;
  box-shadow: 0 0 0 100px #fff inset;
}

.modal-dialog {
  top: 80px;
}

.input-focus:focus {
  border-color: $primaryFocus;
  box-shadow: 0 0 0 0.2rem $primaryFocusShadow;
}

.modal-open {
  padding-right: 0px !important;
}

.text-bold {
  font-weight: 600;
}

.text-link, .text-link:hover {
  color: $blue;
  text-decoration: underline;
  cursor: pointer;
}

.text-fade {
  opacity: .3;
}

.fade-5 {
  opacity: .5;
}

.underline {
  text-decoration: underline;
}

.strikethrough {
  text-decoration: line-through;
  text-decoration-color: red;
}

// Utility crossed-out class for FontAwesome icons
.crossed-out:after {
  content: '|';
  display: block;
  font-size: 1.5em;
  left: 40%;
  position: absolute;
  text-align: center;
  top: -9px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

// Loading Table elements
.table-message {
  background-color: #FFFFFF;
  color: $primary;
  float: left;
  height: 0;
  margin-left: 40%;
  position: absolute;
  text-align: center;
  overflow: visible;
  z-index: 10;
}

.table-message-floater {
  display: inline;
}


// Spinners
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.loading-indicator-spinner {
  display: inline-block;
  vertical-align: text-bottom;
  border: 0.3em solid $primaryFocusShadow;
  border-right-color: $primaryFocus;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

// Growing circle
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.loading-indicator-grow {
  display: inline-block;
  vertical-align: text-bottom;
  background-color: currentColor;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.loading-indicator-ellipsis {
  span {
    display: inline-block;
    border-radius: 50%;
    height: 3px;
    width: 3px;
    margin-left: 2px;
  }

  #ellipsis-1 {
    animation: le-1 1.25s ease infinite;
  }

  ;

  #ellipsis-2 {
    animation: le-2 1.25s ease infinite;
  }

  ;

  #ellipsis-3 {
    animation: le-3 1.25s ease infinite
  }

  ;

  // UX of 0/25/50/75 could be tweaked w/ animation
  @-webkit-keyframes le-1 {
    0% {
      opacity: 0
    }

    25% {
      opacity: 1
    }
  }

  @-webkit-keyframes le-2 {
    0% {
      opacity: 0
    }

    25% {
      opacity: 0
    }

    50% {
      opacity: 1
    }
  }

  @-webkit-keyframes le-3 {
    0% {
      opacity: 0
    }

    25% {
      opacity: 0
    }

    50% {
      opacity: 0
    }

    75% {
      opacity: 1
    }
  }
}

// Override reboot.scss
label {
  margin: 0;
}

.mmb-badge {
  border-radius: 6px;
  font-size: 13px;
  font-weight: 700;
  overflow: hidden;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

#loader-component {
  position: fixed;
  align-items: center;
  color: $grey;
  display: flex;
  flex-direction: column;
  opacity: 50%;

  transform: translateX(-50%) translateY(-50%);
  left: 50vw;
  top: 47.5vh;

  justify-content: center;
  font-weight: 700;
  font-size: 15px;

  user-select: none;
  transition: all 0.15s ease-out;

  opacity: 1;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.whitespace-break {
  white-space: break-spaces;
}

.text-pre {
  white-space: pre;
}

.td-v-align-center td {
  vertical-align: middle;
}

.bcdg {
  background-color: $darker-grey;
}

.mmb-modal-custom {
  position: fixed;
  overflow-y: auto;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-out;
}

.border-none {
  border: 0 !important;
}
