.dashboard {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.dashboard-header {
  font-size: 1.3125em; // Calculates to 21px 
  font-weight: 700;
}

.dashboard-add-client {

  &-button {
    font-weight: 700;
    padding-left: 21px;
    padding-right: 21px;
  }
}

.dashboard-shopper-list-tabs {
  display: flex;
  flex-direction: row;
  margin-top: 14px;
}

.dashboard-shopper-list-tab {
  align-items: center;
  border-bottom: 2px solid white;
  border-left: 1px solid var(--bs-bddark);
  color: var(--bs-dark);
  cursor: pointer;
  display: flex;
  height: 40px;
  line-height: 40px;
  position: relative;
  transition: all $easeInSpeed ease-in;
  user-select: none;
  -webkit-user-drag: none;
  white-space: nowrap;
  width: 145px;

  span {
    font-weight: 700;
    margin-left: 20px;
  }

  &:first-child {
    border-left: none;
  }

  &:hover {
    color: $primaryFocusBorder;
    background-color: $primaryFocusBackground;
    border-bottom: 2px solid $primaryFocusBorder;
    transition: all $easeOutSpeed ease-out;
  }

  &.selected {
    color: $primaryFocusBorder;
    background-color: $primaryFocusBackground;
    border-bottom: 2px solid $primaryFocusBorder;
  }
}

.dashboard-shopper-list-search {
  align-items: center;
  display: flex;
  background-color: $light-grey;
  border: 1px solid $dark-grey;
  flex-wrap: wrap;
  height: 60px;
  line-height: 60px;
  white-space: nowrap;

  &-break {
    flex-basis: 0%;
  }

  form {
    flex-wrap: wrap;
    height: 60px;
  }
}

// Break search bar into two rows
@media (max-width: 900px) {
  .dashboard-shopper-list-search {

    height: 110px;

    &-break {
      flex-basis: 100%;
    }

    form {
      height: 120px;
    }
  }

  .dashboard-shopper-list-search-inputs:not(:first-of-type) {
    margin-top: -30px;
  }

  // Ensure labels match 
  .dashboard-shopper-list-search-inputs-label {
    min-width: 85px;
  }
}

.dashboard-shopper-list-search-inputs {
  display: inline-flex;
  height: 60px;
  align-items: center;

  &-label {
    margin-left: 20px;
  }

  &-name {
    margin-left: 15px;
    width: 150px;
  }

  &-state {
    margin-left: 15px;
    width: 75px;
  }

  &-city {
    margin-left: 15px;
    width: 150px;
  }

  &-phone {
    margin-left: 15px;
    width: 150px;
  }

  &-disposition {
    // Remove default dropdown caret
    appearance: none;
    margin-left: 15px;
    padding-right: 24px;
    width: 150px;
    text-overflow: ellipsis;
    user-select: none;
  }

  &.buttons {
    margin-right: 20px;
    margin-left: auto;

    button {
      font-weight: 700;
      padding-left: 21px;
      padding-right: 21px;
    }

    button:first-child {
      margin-right: 16px;
    }
  }
}

.dashboard-shopper-list-count {
  align-items: center;
  display: flex;
  height: 50px;
  line-height: 50px;
  margin-left: 20px;
  font-family: Helvetica Neue, Helvetica;
  font-size: 12px;
}

.dashboard-shopper-list {
  width: 100%;

  &-container {
    margin-left: 30px;
    margin-right: 20px;
  }

  // Nested padding/margins required for table construction
  &-header {
    div {
      // Override our cell line-height to have clickable sort icons
      line-height: revert !important;
      margin-bottom: 9px;
      margin-top: 5px;
      text-transform: initial;
      user-select: none;
    }

    // Force block display so they fill up given space
    #dashboard-shopper-list-shopper-name {
      display: block;
    }

    #dashboard-shopper-list-shopper-last-updated {
      display: block;

    }

    #dashboard-shopper-list-shopper-state {
      display: block;

    }

    #dashboard-shopper-list-shopper-city {
      display: block;

    }

    #dashboard-shopper-list-shopper-phone {
      display: block;

    }

    #dashboard-shopper-list-shopper-disposition {
      display: block;

    }

    #dashboard-shopper-list-shopper-lead-type {
      display: block;
    }

    #dashboard-shopper-list-shopper-lead-history {
      display: block;
    }
  }

  &-cell {
    cursor: pointer;
    padding: 0;
    text-transform: capitalize;

    > div {
      line-height: 40px;
      margin-left: 20px;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }

  &-row {
    background-color: $bright-grey;
    outline: 1px solid $medium-grey;
    height: 40px;
    max-height: 40px;

    margin-top: -1px;
    display: inline-block;
    width: 100%;
  }

  // Ensure none of our cells overflow and increase row height
  #dashboard-shopper-list-shopper-name {
    width: 12%;
    height: 40px;
    display: inline-flex;
  }

  #dashboard-shopper-list-shopper-last-updated {
    width: 14%;
    height: 40px;
    display: inline-flex;
  }

  #dashboard-shopper-list-shopper-state {
    width: 7.5%;
    height: 40px;
    display: inline-flex;
  }

  #dashboard-shopper-list-shopper-city {
    width: 9%;
    height: 40px;
    display: inline-flex;
  }

  #dashboard-shopper-list-shopper-phone {
    width: 12.5%;
    height: 40px;
    display: inline-flex;
  }

  #dashboard-shopper-list-shopper-disposition {
    width: 20%;
    height: 40px;
    display: inline-flex;
  }

  #dashboard-shopper-list-shopper-lead-type {
    width: 10%;
    height: 40px;
    display: inline-flex;
  }

  #dashboard-shopper-list-shopper-calls {
    width: 12%;
    height: 40px;
    display: inline-flex;
  }

  #dashboard-shopper-list-shopper-lead-history {
    width: 12.5%;
    height: 40px;
    display: inline-flex;
    padding: 0;
  }
}

// Unique cell that cannot use nested padding
.dashboard-shopper-list-shopper-lead-history {
  padding: 0;

  &-container {
    cursor: pointer;
    display: flex;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    // Padding used for right-hover because we use fixed widths to prevent resizing on load
    padding-right: 7%;
    transition: background-color $easeInSpeed ease-in;
    user-select: none;
    -webkit-user-drag: none;
    // Ensure border goes on top of our row
    z-index: 5;

    &.disabled {
      cursor: auto;

      &:hover {
        background-color: $bright-grey;
      }
    }

    &:hover,
    &.open {
      background-color: $primaryHighlightBackground;
      transition: background-color $easeOutSpeed ease-out;
    }

    &.open {
      box-shadow: 0 1px 0 0 $primaryHighlightBackground;
    }

    span {
      margin-left: 11px;
    }
  }

  &-toggle {
    height: 40px;
    line-height: 40px;
    margin-left: 9px;
    width: 40px;
    text-align: center;
  }
}

.dashboard-shopper-list-inactive-leads-dropdown {
  width: 100%;

  // Nesting is required as this is technically considered a row
  &-container {
    background-color: $primaryHighlightBackground;
    display: flex;
    margin-left: 20px;
    margin-right: 21px;
  }

  td {
    padding: 0;
  }

  &-container {
    align-items: center;
    padding-bottom: 18px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 14px;
  }

  &-blurb {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    width: 252px;
  }

  &-table {
    font-size: 13px;
    margin-left: 33px;
    width: calc(100% - 252px);

    table {
      width: 100%;
    }

    &-header th {
      cursor: auto;
      padding-bottom: 10px;
      padding-left: 20px;
      text-transform: unset;
    }

    tbody {
      background-color: $primaryHighlight;
    }

    &-row {
      border: 1px solid var(--bs-nbgrey);

      td {
        padding-bottom: 10px;
        padding-left: 20px;
        padding-top: 10px;
      }
    }
  }
}

// Third view for entire dashboard system
@media (max-width: $phoneMaxWidth) {
  .dashboard {
    padding-left: 0;
    padding-right: 0;
  }

  .dashboard-add-client {
    right: 0px;
    top: 0px;
  }

  .dashboard-shopper-list-search {
    padding-bottom: 8px;
    padding-left: 4px;
    padding-top: 4px;
    height: auto;

    form {
      height: auto;
    }


    &-break {
      flex-basis: 0%;
    }
  }

  .dashboard-shopper-list-search-inputs {
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    width: 100%;

    &-label {
      display: none;
    }

    &-* {
      width: auto;
    }

    input {
      width: 47.5%;
      margin: 4px;
    }

    &-disposition {
      width: 100%;
      margin-left: 11px;
    }

    &.buttons {
      justify-content: space-around;
      margin-right: 11px;

      button {
        padding-left: 0;
        padding-right: 0;
        width: 47.5%;

        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  .dashboard-header {
    margin-left: 12px;
  }

  .dashboard-add-client {
    margin-right: 12px;
  }

  .dashboard-shopper-list-tabs {
    justify-content: space-around;

    // Force each tab to take as much space as it can
    div {
      width: 100%;
      font-size: 13px;
    }
  }

  // Override our two-line search box from medium view
  .dashboard-shopper-list-search-inputs:not(:first-of-type) {
    margin-top: 4px;
    width: 47.5%;
  }

  .dashboard-shopper-list-tab span {
    margin-left: 0px;
    text-align: center;
    width: 100%;
  }

  .dashboard-shopper-list-container {
    margin-left: 8px;
    margin-right: 8px;
  }

  // Used to override inline-flex definitions
  #dashboard-shopper-list-shopper-last-updated,
  #dashboard-shopper-list-shopper-state,
  #dashboard-shopper-list-shopper-city {
    display: none !important;
  }


  // We now only have four visible cells
  #dashboard-shopper-list-shopper-name,
  #dashboard-shopper-list-shopper-phone,
  #dashboard-shopper-list-shopper-disposition,
  #dashboard-shopper-list-shopper-inactive-leads {
    width: 25% !important;
  }
}

.bcdg {
  background-color: $darker-grey;
}

.dashboard-calls-table {
  border: 1px solid var(--bs-medium-grey) !important;
  background-color: var(--bs-primaryFocusBackground) !important;
  margin-bottom: 0px !important;
}

.dashboard-row-hl {
  border: 1px solid var(--bs-primaryFocusBorder) !important;
}
