$navbar-height: 51px;

.mobile-page-item {
  height: 6vh;
  width: 100%;
}

.mobile-page-link {
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  line-height: 6vh;
}

.mobile-pagination {
  width: 100%;
}

.mobile-navbar, .mobile-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 10;
  width: 100%;
  height: $navbar-height;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%) !important;
}

.mobile-footer {
  top: auto;
  bottom: 0;
  height: 1.5 * $navbar-height;
  box-shadow: 0 -0.125rem 0.25rem rgb(0 0 0 / 20%) !important;
  justify-content: center;
}

.mobile-container {
  color: black;
  font-family: 'Source Sans Pro', sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 18px;
  height: calc(100% - #{$navbar-height});
  margin-top: $navbar-height;
  overflow-y: scroll;
  position: fixed;
}

.mobile-container input, .mobile-container button, .mobile-container select {
  border-radius: 8px;
  height: 50px;
}

.mobile-container input {
  border-color: #AAAAAA;
  padding-left: 20px;
}

.mobile-container select {
  border-color: #AAAAAA;
  margin-top: 12px;
  margin-bottom: 8px;
  padding-left: 10px;
}

.mobile-container label {
  margin-top: 4px; 
  margin-bottom: 4px;
}

.mobile-container .password-forgot {
  margin-top: 25px;
  color: #007AFA !important;
  font-size: 18px;
  text-decoration: underline;
}

.mobile-page-header {
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  margin-top: 30px;
  padding-left: 28px;
  padding-right: 28px;
}

.mobile-page-navigation {
  color: #505050;
  cursor: pointer;
  display: flex;
  height: 27px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  vertical-align: middle;
}

.mobile-lead-name {
  text-transform: capitalize;
}

.mobile-lead-details {
  margin-top: 9px;
  line-height: 24px;
}

.mobile-lead-details b {
  font-weight: 600;
}

.mobile-lead-telephone {
  margin-top: 18px;
}

.mobile-font-large {
  font-size: 27px;
  font-weight: 700;
}

.mobile-font-reg {
  font-size: 18px;
}

/* Font larger than 16px will force a zoom-in on mobile */
.mobile-font-small {
  font-size: 16px;
}

.mobile-font-it {
  font-size: 12px;
  font-style: italic;
}

.mobile-page-content {
  padding-left: 28px;
  padding-right: 28px;
  margin-top: 16px;
}

.mobile-page-list {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 12px;
  height: 82%;
}

.mobile-login .password-submit {
  flex-direction: column;
  width: 100%;
}

.mobile-login {
  & input[name=password] {
    border-radius: 8px !important;
  }

  & .input-group-append {
    position: absolute;
    right: 2px;
    top: 12px;
  }

  & .input-group-append:focus {
    outline: 0;
  }

  & .input-group-text {
    border: 0;
    background-color: white;
  }

  & .fa-eye {
    color: #196FCF;
    cursor: pointer;
  }

  & .crossed-out:after {
    bottom: -2px;
    color: #196FCF;
    left: 16px;
  }
}

.mobile-table {
  width: 100%;
}

.mobile-leads-list {
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mobile-leads-list tr {
  height: 58px;
  border-radius: 4px;
  display: block
}

.mobile-leads-list td {
  border-radius: 4px;
}

.mobile-leads-list-new-badge {
  background-color: #2FB973;
  border-radius: 4px;
  color: white;
  display: inline;
  font-size: 15px;
  line-height: 50%;
  margin-left: 8px;
  padding-bottom: 1px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  vertical-align: middle;
}

.mobile-table-row {
  width: 100%;
  margin-bottom: 10px;
}

.mobile-table-row-element {
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  display: flex;
  height: 58px;
}

.mobile-table-col {
  width: 50%;
  justify-content: left;
}

.select-mobile {
  border-radius: 8px;
  background-color: white;
}

.btn-mobile {
  border-radius: 8px !important;
  color: white;
  font-size: 18px;
  text-weight: bold;
  -webkit-appearance: button-bevel;
}

.btn-mobile-primary {
  background-color: #F35B2C;
  border-color: #F35B2C;
}

.btn-mobile-primary:hover {
  background-color: #F35B2C;
  border-color: #F35B2C;
  color: white;
}

.btn-mobile-secondary {
  background-color: #656565;
  border-color: #656565;
}

.btn-mobile-secondary:hover {
  background-color: #656565;
  border-color: #656565;
}

.btn-list-telephone {
  height: 56px;
  width: 60px;
  border-radius: 0px 4px 4px 0px !important;
}

.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #AAAAAA;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: #AAAAAA;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: #AAAAAA;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #AAAAAA;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color: #AAAAAA;
}
input::placeholder, textarea::placeholder {
   color: #AAAAAA;
}

.mobile-container select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none; 
    background-color: transparent!important;
}

.mobile-container select::-ms-expand {
    display: none;
}

.mobile-container select + i.fa {
  float: right;
  margin-top: -41px;
  margin-right: 5px;
  pointer-events: none;
  /* (for browsers that don't support the syntax used above) */
  background-color: #fff;
  padding-right: 5px;
  color: black;
}

a, a:hover, a:active, a:visited, a:focus {
  text-decoration:none;
}
