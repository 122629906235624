$navbarHeight: 44px;

.navpage {
  // SASS interpolation is required for calc
  margin-top: calc(#{$navbarHeight} + 20px);
}

.navpage.extended {
  margin-top: $navbarHeight * 2;
}

.navbar {
  box-sizing: content-box;
  display: flex;
  font-size: 15px;
  height: $navbarHeight;
  justify-content: flex-start !important;
  padding: 0;
  width: 100%;
  /* Some bootstrap components require this i.e. modals */
  /* todo(joeysapp): However now our nav doesn't get greyed out */
  z-index: 4201;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.navbar-icon {
  cursor: pointer;
  max-width: 120px;
  height: auto; 
  margin-left: 18px;
  margin-right: 8px; 
}

/* Buttons on top & panels on infobar have identical positioning */
.navbar-button, .navbar-shopper-infobar-panel {
  align-items: center;
  cursor: auto;
  display: flex;
  height: $navbarHeight;
  line-height: $navbarHeight;
  position: relative;
  user-select: none;
  -webkit-user-drag: none;

  &.disabled {
    cursor: auto;
  }

  &-name {
    text-transform: capitalize;
  }
}

.navbar-button-text {
  background-color: white;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  // Line here is telling what height to color on hover
  line-height: 28px;
  margin-left: 1px;
  margin-right: 1px;
  padding-left: 21px;
  padding-right: 21px;
  width: 100%;
  text-underline-offset: 4px;
  transition: background-color $easeInSpeed ease-in, color $easeInSpeed ease-in;

  &:hover {
    background-color: $primaryFocusBackground;
    color: $primaryFocus;
    transition: background-color $easeOutSpeed ease-out, color $easeOutSpeed ease-out;
  }

  &.selected {
    color: $primaryFocus;
    text-decoration: underline;  
  }

  &.disabled {
    color: $grey;
    cursor: auto;
    text-decoration: none;

    &:hover {
      background-color: white;
      color: $grey;  
    }
  }
}

.navbar-guide {
  margin-left: auto;
}

.navbar-agent {
  background-color: white;
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  transition: background-color $easeInSpeed ease-in;
  width: 170px;

  &:hover {
    color: $primaryFocus;
    background-color: var(--bs-bdlight);
    transition: all $easeOutSpeed ease-out; 
  }

  &.open {
    color: $primaryFocus;
    background-color: var(--bs-bdlight);
  }
}

.navbar-client-buttons {
  white-space: nowrap;

  // Each specific button
  div {
    background-color: white;
    height: 44px;
    width: auto;  
  }
}

// Used to make all hovers on our dropdown identical maintaining size
.navbar-client-buttons:first-child {
  padding-left: 5px;
}

// Used to ensure in compact view the text location is matching for hover
.navbar-client-buttons:first-child {
  padding-left: 5px;
}

// Parent for narrow view dropdown toggle, gives us circular hover
.navbar-client-dropdown-toggle, .navbar-shopper-infobar-dropdown-toggle {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  line-height: 32px;
  height: 26px;
  width: 26px;
  text-align: center;

  &:hover, &.open {
    color: $primaryFocus;
    background-color: $primaryFocusBackground;
    transition: background-color $easeOutSpeed ease-out; 
  }
}

// Narrow view dropdown icon itself (up/down caret)
.navbar-client-dropdown-toggle-icon {
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  -webkit-user-drag: none;

  .disabled {
    color: $grey;
  }
}

.navbar-help {
  background-color: white;
  border-radius: 2px;
  color: $blue;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 12px;
  padding-right: 12px;
  transition: background-color $easeInSpeed ease-in;

  &:hover {
    background-color: $primaryFocusBackground;
    transition: background-color $easeOutSpeed ease-out;  
  }

  // todo(joeysapp): In addressing the guide, ensure this animates properly
  &.selected {
    color: $primaryFocus;
  }
}

.navbar-agent-icon {
  font-size: 22px;
  left: 17px;
  position: absolute;
  top: 25%;
}

.navbar-agent-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar-agent-dropdown-toggle {
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  line-height: 32px;
  margin-right: 10px;
  margin-left: auto;
  margin-top: 0;
  height: 26px;
  width: 26px;
  text-align: center;
}

.navbar-agent-dropdown-toggle-icon {
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  -webkit-user-drag: none;
}

.navbar-agent-dropdown-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  top: $navbarHeight + 10px;
  right: 10px;
  transition: all $easeInSpeed ease-in;
  background-color: white;

  color: black;
  border: 1px solid $primaryFocusBorder;

  div {
    background-color: white;
    height: $navbarHeight;

    // Second-nested div is the actual url
    div {
      // Hoverable content
      span {
        width: 190px;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }

  &.open {
    padding: 10px;
    box-shadow: 0px 0px 6px grey;
  }

  &.closed {
    height: 0;
    border: 0;
    padding: 0;
  }

  .navbar-client-buttons div:not(:first-child) {
    box-shadow: none;
    display: block;
  }
}

//
// Shopper Infobar
//

.navbar-shopper-infobar {
  box-sizing: border-box;
  display: flex;
  font-size: 15px;
  height: $navbarHeight;
  justify-content: flex-start !important;
  margin-top: $navbarHeight;
  padding: 0;
  width: 100%;
  /* Some bootstrap navbar props require this, underneath our top navbar */
  z-index: 4200;

  /* fixed-top */
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.navbar-shopper-infobar-static-panels {
  display: flex;
  flex-direction: row;
}

// Display logic handled differently for stacked non-hoverable items
.navbar-shopper-infobar-panel, .navbar-shopper-infobar-links {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  height: $navbarHeight;
  min-height: $navbarHeight;

  // Vertically center our panels 
  justify-content: center;
  line-height: normal;
  white-space: nowrap;

  // Allow Agents to select data, but not labels
  user-select: text;
  -webkit-user-drag: text;

   span {
    display: inline-block;
    margin-left: 23px;
    margin-right: 23px;
    text-underline-offset: 4px;
  }

  &-name {
    font-size: 18px;
    max-width: 200px;
    min-width: 150px;
    width: 200px;
  }

  &-label {
    font-size: 13px;
    font-weight: bold;
    user-select: none;
    -webkit-user-drag: none;
  }
}

// Ensure SOA badge looks pretty
#navbar-shopper-infobar-panel-soa-status {
  margin-left: 7px;
  margin-right: 7px;
}

// Specific positioning for up/down caret holder
.navbar-shopper-infobar-dropdown-toggle {
  display: none;
  line-height: 30px;
  position: absolute;
  right: -16px;
}

.navbar-shopper-infobar-static-panels.closed {
  height: $navbarHeight;
  overflow: hidden;
}

.navbar-shopper-infobar-static-panels.open {
  height: calc(var(--navbarHeight) * 6);
}


.navbar-shopper-infobar-links {
  display: flex;
  flex-direction: row;
  margin-right: 0;

  span {
    font-size: 20px;
    height: $navbarHeight;
    line-height: 37px; // Used for link centering (pharma, etc.)
    margin-left: 0;
    margin-right: 0;
    width: $navbarHeight;
  }
}

.navbar-shopper-infobar-link {
  position: relative;
  border-right: 1px solid var(--bs-bddark);
  border-left: 1px solid transparent;
  user-select: none;
}

.navbar-si-link-highlighted {
  position: relative;
  border-right: 1px solid $highlightFocusBorder;
  border-left: 1px solid $highlightFocusBorder;
  border-bottom: 1px solid $highlightFocusBorder;
  box-shadow: 0px 0px 6px grey;
  user-select: none;
}

// Specific coordinates to get a circular span we can hover over
.navbar-shopper-infobar-link-icon {
  line-height: 38px;
  text-align: center;
  margin-right: -1px;
}

.navbar-shopper-infobar-link-icon-hover {
  background-color: var(--bs-bdlight);
  border-radius: 50%;
  cursor: pointer;
  height: 75% !important;
  width: 75% !important;
  text-align: center;
  vertical-align: middle;

  &:hover {
    background-color: $primaryFocusBackground;
    transition: background-color $easeOutSpeed ease-out;
  }
}

.navbar-shopper-infobar-hover {
  background-color: var(--bs-bdlight);
  cursor: pointer;
  height: 95%;
  padding-top: 4px;

  &:hover {
    background-color: $primaryFocusBackground;
    transition: background-color $easeOutSpeed ease-out;
  }
}

// Media Views

// Navbar has two modes, large or small. Below is smallest 'large mode' can be
// Referenced in src/components/nav/topbar/index.jsx
$navbarModeLarge: 1000px;
$infobarModeLarge: 1300px;

// Navbar Small view (width < navbarModeLarge)
@media (max-width: $navbarModeLarge) {
  .navbar-client-buttons {
    flex-direction: column;
    position: absolute;
    top: 0;
  }

  .navbar-client-dropdown-toggle {
    display: inline-block;
  }

  .navbar-client-buttons div:not(:first-child) {
    display: none;
    width: 0;
    height: 0;
  }

  .navbar-client-buttons div.open:not(:first-child) {
    display: block;
    padding-left: 10px;
    padding-right: 11px;
    width: auto;
    height: 42px;
  }
}

// Navbar Large/default view (width > navbarModeLarge)
@media (min-width: $navbarModeLarge) {
  .navbar-client-buttons {
    flex-direction: row;
    width: auto;
  }

  .navbar-client-buttons div:not(:first-child) {
    box-shadow: none;
    display: block;
  }
}

// ShopperInfobar Small view (width < infobarModeLarge)
@media (max-width: $infobarModeLarge) {
  .navbar-shopper-infobar-dropdown-toggle {
    display: inline-block;
  }

  .navbar-shopper-infobar-static-panels {
    flex-direction: column;
    min-width: 200px;
  }

  .navbar-client-buttons {
    width: 150px;
  }

  .navbar-button-text {
    // The selected nav item, in compact view, should not have hover functionality
    &.selected {
      &:hover {
        // background-color: white;
        // font-weight: 700;
      }
    }
  }
}

// ShopperInfobar Large/default view (width > infobarModeLarge)
@media (min-width: $infobarModeLarge) {
  // !important is necessary from nested assignment
  .navbar-shopper-infobar-dropdown-toggle {
    display: none !important;
  }

  .navbar-shopper-infobar-static-panels {
    flex-direction: row;
    min-width: auto;
  }

  .navbar-shopper-infobar-panel {
    display: flex !important;
  }
}

// Third view for entire nav system; narrower than a given industry standard
// Change all components
@media (max-width: $phoneMaxWidth) {
  .navpage {
    margin-top: calc(#{$navbarHeight} + 12px);
  }

  .navbar-agent-name {
    display: none;
  }

  .navbar-agent {
    width: 80px;
  }

  .navbar-client-buttons {
    // todo(joeysapp): Likely not necessary now that site logos have max-width set
    // left: 165px;
  }

  .navbar-button-text {
    padding-left: 12px;
    padding-right: 12px;
  }

  .navbar-agent-dropdown-content {
    right: 0;
  }

  .navbar-guide {
    visibility: hidden;
  }

  .dashboard-shopper-list-tabs {
    justify-content: space-around;

    // Force each tab to take as much space as it can
    div {
      width: 100%;
      font-size: 13px;
    }
  }

  .dashboard-shopper-list-tab {
    span {
      margin-left: 8px;
    }
  }
}

.navbar-drug-popover {
  position: absolute;
  background-color: white;
  border: solid 1px $highlightFocusBorder;
  box-shadow: 0px 0px 6px grey;
}

.navbar-count-badge {
  position: absolute;
  font-size: 13px !important;
  font-weight: bold;
  width: 18px !important;
  height: 18px !important;
  background-color: $primary;
  color: white;
  text-align: center;
  border-radius: 50%;
  right: 3px;
  top: 3px;
  user-select: none;
  cursor: pointer;
}

.navbar-drug-card {
  border: solid 1px $dark-grey;
  white-space: break-spaces;
  margin-right: 1rem;
  margin-left: 1rem;
  padding: auto;
  transition: all $easeInSpeed ease-in;
}
.navbar-drug-card[role] {
  &:hover, 
  &.active{
    color: $primaryFocus;
    border-color: $primaryFocus;
  }
  &.disabled {
    color: black !important;
    border-color: $dark-grey !important;
    cursor: auto !important;
    opacity: 0.3;
  }
}

.navbar-drug-card-container {
  // border: solid 1px $dark-grey;
  padding: 0px;
  max-height: 274px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.navbar-drug-card-container .row {
  margin-left: 0px;
  margin-right: 0px;
}

.navbar-drug-card-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.navbar-drug-card-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

// FontAwesome star svg elements will resize without !important
.navbar-doc-star {
  height: 20px !important;
  width: 20px !important;
  min-height: 20px !important;
  min-width: 20px !important;
  line-height: auto !important;
  display: inline-flex !important;

  // Handle hovering UX in MarkImportant component
  &:hover { }
}

.navbar-notes-row {
  border: solid 1px $warning-border;
  background-color: $warning-bg;
  padding: 8px;
  justify-content: space-between;
  // margin-right: 1rem;
  // margin-left: 1rem;
}

.navbar-note-details-link {
  color: $blue;
  text-decoration: underline;
}

.navbar-notes-nav-btn {
  color: $dark-grey;
  border: solid 1px $dark-grey;
}

.navbar-notes-nav-btn:hover {
  color: $darkest-grey;
  border: solid 1px $darkest-grey;
}

// Password Reset Modal
.password-reset-input-password-visibility-toggle {
  color: #939598; 
  cursor: pointer;
  font-size: 20px;
  line-height: 36px;
  position: absolute;
  user-select: none;
  width: 32px;
  z-index: 5;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.password-reset-password-visibility-toggle-span {
  // Give user a larger click area for toggling
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  position: relative;
}

.footer-nav {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  color: red;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #CBCDCE;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.08);
  z-index: 4200;
  background-color: white;
}

/* Release Notes, Help & Privacy */
.help-button {
  display: flex;
  flex-direction: column;
  font-size: 18px;

  padding: 20px;

  background-color: #F8F9FA;
  border: 1px solid $dark-grey;
  color: black !important;

  box-shadow: 0 1px 3px #CBCDCE;
  cursor: pointer;

  margin-bottom: 20px;

  transition: all $easeOutSpeed ease-out; 

  &:hover {
    background-color: $primaryFocusBackground;
    border: 1px solid $primaryFocusBorder;
    transition: all $easeInSpeed ease-in; 
  }
}
