/* Login Landing */
.login-landing, .signup-landing{
  &-body {

    ::placeholder {
      color: $grey !important
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  &-left {
    position: relative;
    text-align: center;
    width: 60vw;
  }

  &-right {
    width: 40vw;
    margin-left: 2.5vw;
  }

  &-logo {
    max-width: 661px;
    width: 661px;
    margin-top: 21.75vh;
    margin-left: 1.65vw;
    // Prevent dragging of image
    pointer-events: none;
    user-select: none;
    -webkit-user-drag: none; 
  }
}

.signup {
  // height: 100%;
  width: 80%;
}

.signup-landing-left, .signup-landing-right {
  position: relative;
  // width: 50vw;
}

.signup-landing-left {
  width: 60vw;
  white-space: nowrap;
  .alert {
    white-space: pre-line;
  }
}

// .signup-landing-right {
//   margin-right: 2.5vw;
// }

.login-header-container {
  position: absolute;
  top: 21.75vh;
}

.signup-header-container {
  position: absolute;
  top: 16vh;
  padding-bottom: 48px;
}

.signup-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.login-header-text {
  font-size: 42px;
  font-weight: 750;
  line-height: 56px;
  margin-bottom: 22px;
  width: 300px;
  user-select: none;
}

.login-header-logo {
  max-width: 160px;
  margin-bottom: 10px;
  margin-left: -4px;
  user-select: none;
  -webkit-user-drag: none;
}

/* Input Elements */
.login-input {
  height: 50px;
  width: 350px;
}
.login-input:focus { 
  border-color: $primaryFocus;
  box-shadow: 0 0 0 4px $primaryFocusShadow;
}

.login-input-password-group {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  width: 350px;
}

.login-input-password-visibility-toggle {
  color: #939598; 
  cursor: pointer;
  font-size: 28px;
  line-height: 52px;
  position: absolute;
  user-select: none;
  width: 48px;
  z-index: 5;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.login-input-password-visibility-toggle-span {
  // Give user a larger click area for toggling
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  position: relative;
}

/* Login Verification */
.login-verification-text {
  color: black;
  font-size: 15px;
  margin-bottom: 20px;
  width: 350px;
}

.login-verification-input-code {
  height: 50px;
  margin-bottom: 20px;
  padding-bottom: 3px;
  padding-left: 19px;
  width: 350px;
}

.login-verification-input-code:focus {
  border-color: $primaryFocus;
  box-shadow: 0 0 0 0.2rem $primaryFocusShadow;
}

.login-verification-group {
  margin-bottom: 30px;
  padding-top: 20px;
}

.login-verification-submit-button, .login-verification-resend-button {
  font-size: 21px;
  font-weight: 600;
  height: 50px;
  margin-right: 20px;
  width: 152px;
}

.login-verification-back-button {
  cursor: pointer;
  font-size: 15px;
  font-weight: 550;
  position: relative;
  user-select: none;
  -webkit-user-drag: none;
}

.login-verification-back-button-caret {
  font-size: 24px;
  position: absolute;
  top: -1px;
  left: 2px;
}

.login-verification-back-button-text {
  margin-left: 22px;
}

.signup-disclaimer {
  background-color: $primaryFocusBackground;
  padding: 2.25rem;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  font-size: 15px;
  width: 80%;
}

.signup-disclaimer-2 {
  padding: 2.25rem;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  font-size: 15px;
  border: 1px solid lightgrey;
  height: 100%;
}

.signup-disclaimer-3 {
  background-color: $primaryFocusBackground;
  padding: 2.25rem;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  font-size: 15px;
}

// Medium view
@media (max-width: 1000px) {
  .login-landing-left {
    align-items: center;
    position: absolute;
    filter: blur(5px);
    width: 100vw;
    overflow: hidden;
  }

  .login-landing-right {
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
  }

  .login-header-container {
    padding: 1.5em;
    background-color: rgba(255, 255, 255, 0.75);
    
    iframe {
      margin-left: 0px;
    }
  }

  .login-landing-logo { 
    margin-left: -2vw;
  }

  .signup {
    width: 100%;
  }

  .signup-row {
    flex-direction: column;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--bs-grey);
  }

  .client-info-input {
    width: 100% !important;
    margin-left: 0px !important;
  }
}

// Smallest view (mobile)
@media (max-width: $phoneMaxWidth) {
  
}
